import { Status } from '@googlemaps/react-wrapper';
import {
	DrivenBox, DrivenButton,
	DrivenContainer,
	DrivenLink,
	DrivenMap,
	DrivenTypography,
	MapWrapper,
	Marker,
} from '@drivenbrands/driven-components';
import { useMediaQuery, useTheme } from '@mui/material';
import { PageProps, graphql } from 'gatsby';
import { useState } from 'react';

import CTABanner from '../components/cta-banner';
import { formatPhoneNumber } from '../components/forms';
import { GatsbyLinkBehavior } from '../components/gatsby-link-behavior';
import MarkdownContent from '../components/mdx/markdown-content';
import { SectionType } from '../components/section';
import { CustomerTestimonials } from '../components/customer-testimonials';
import { SEO } from '../components/seo';
import { ComponentMatch } from '../hooks';
import {
	generateHeadData, generateHeading,
	getMapMarker,
	stateNameToAbbreviation,
} from '../utils';

import { LocationDetailBreadcrumb } from '../components/breadcrumbs/location-details-breadcrumbs';
import { consolidateStoreHours, formatStoreHours } from '../utils/consolidateStoreHours';
import { captureCustomDataLayerEvent } from '../services';

const LocationDetailsPage = ({ pageContext, data: { ctaBanner, contentfulLocationContent, storeLocation, detailPage }, ...props }: PageProps<Queries.DetailsPageQueryQuery>) => {
	const [mapStatus, setMapStatus] = useState(Status.LOADING);
	const theme = useTheme();
	const isTablet = useMediaQuery(theme.breakpoints.up('md'));

	const store = storeLocation;

	if (!store) return null;

	const storeStateAbbr = stateNameToAbbreviation(store.store_state)?.toLowerCase() ?? '';
	const locationDetailBlocks = detailPage?.nodes[0]?.blocks;
	const locationContentData = contentfulLocationContent;
	const locationContent = (fallbackContent?: string) => {
		let content;

		if (locationContentData && locationContentData.content?.childMarkdownRemark?.rawMarkdownBody) {
			return locationContentData.content.childMarkdownRemark.rawMarkdownBody;
		}

		return (
			content ||
			`${fallbackContent}`.replace(
				'[CITY_NAME_DO_NOT_EDIT]',
				store.store_city || store.store_state || ''
			)
		);
	};

	const locationOffers = (blocks: any) => {
		const mobileOfferCheckListItem = {
			icon: 'check',
			isDisabled: false,
			description: 'Mobile Auto Glass Repair',
		};
		if (locationContentData?.mobileRepair) {
			for (let i = 0; i < blocks.listItems.length; i += 1) {
				if (blocks.listItems[i].description === 'Mobile Auto Glass Repair') {
					return ComponentMatch([blocks]);
				}
			}
			blocks.listItems.push(mobileOfferCheckListItem);
		}

		return ComponentMatch([blocks]);
	};

	return (
		<DrivenBox>
			<DrivenContainer>
				<LocationDetailBreadcrumb
					store_city={store.store_city}
					store_state={store?.store_state}
					storeStateAbbr={storeStateAbbr}
					theme={theme}
					path={store.path}
				/>
				{pageContext?.store.storeStatus === "Unverified Closed" && (
					<DrivenBox sx={{
						backgroundColor: '#ffd000',
						color: theme.palette.common.black,
						display: 'flex',
						gap: 2,
						borderRadius: 2,
						py: 2.5,
						px: { xs: 2, sm: 3 }
					}}>
						<DrivenBox>
							<DrivenBox component='svg' sx={{
								width: 24,
								height: 24,
							}}>
								<use href='/assets/images/icons/exclamation-circle.svg#exclamation-circle'/>
							</DrivenBox>
						</DrivenBox>
						<DrivenBox>
							<DrivenTypography component='p' variant='h5' sx={{
								lineHeight: '1!important',
								mb: 1
							}}>
								Location Temporarily closed
							</DrivenTypography>
							<DrivenTypography component='p'>
								This location is temporarily closed due to unforeseen circumstances. We apologize for the inconvenience, please check back later.
							</DrivenTypography>
						</DrivenBox>
					</DrivenBox>
				)}
				<DrivenBox sx={{
					display: 'flex',
					flexDirection: isTablet ? 'row' : 'column',
					gap: 3,
					my: isTablet ? 6 : 3,
				}}>
					<DrivenBox sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 3
					}}>
						<DrivenTypography
							component='h1'
							variant='h1'
							sx={{
								fontSize: { xs: 30, sm: 36, md: 40 },
								gridArea: 'heading',
							}}
						>
							{locationContentData?.heading || generateHeading(store.store_address, store.store_id)}
						</DrivenTypography>
						<DrivenButton
							color='primary'
							href='/online-estimate/'
							LinkComponent={GatsbyLinkBehavior}
							variant='contained'
							sx={{
								borderRadius: 30,
								height: 48,
								textTransform: 'uppercase',
								width: 'fit-content',
							}}
							onClick={() =>
								captureCustomDataLayerEvent('location_page_get_quote', {
									store_id: store?.store_id
								})
							}
						>
							Get a quote
						</DrivenButton>
						<DrivenBox>
							<DrivenTypography variant='subtitle1'>
								Address
							</DrivenTypography>
							<DrivenTypography
								component='div'
								variant='body2'
								display='inline-flex'
							>
								<DrivenTypography component='address'>
									{store.store_address}
									<br />
									{`${store.store_city}, ${store.store_state} ${store.store_postcode}`}
								</DrivenTypography>
							</DrivenTypography>
						</DrivenBox>
						<DrivenBox sx={{
							display: 'flex',
							flexDirection: isTablet ? 'row' : 'column',
							gap: isTablet ? 3 : 2,
						}}>
							<DrivenLink
								href={`https://www.google.com/maps?ll=${store.store_lat},${store.store_long}&z=13&t=m&hl=en-US&gl=US&mapclient=embed&daddr=${store.geo_address}`}
								target='_blank'
								sx={{
									height: '100%',
									display: 'flex',
									gap: 1,
									fontWeight: 600,
									color: '#0070B9'
								}}
							>
								<DrivenBox component='svg' sx={{
									width: 24,
									height: 24,
									fill: '#0070B9'
								}}>
									<use href='/assets/images/icons/turn-right-diamond.svg#turn-right-diamond'/>
								</DrivenBox>
								Get Directions
							</DrivenLink>
							<DrivenLink
								href={`tel:${formatPhoneNumber(store.organic_phone || store.store_phone)}`}
								sx={{
									display: 'flex',
									gap: 1,
									fontWeight: 600,
									color: '#0070B9'
								}}
							>
								<DrivenBox component='svg' sx={{
									width: 24,
									height: 24,
									fill: '#0070B9'
								}}>
									<use href='/assets/images/icons/phone-square.svg#phone-square'/>
								</DrivenBox>
								{formatPhoneNumber(store.organic_phone || store.store_phone)}
							</DrivenLink>
						</DrivenBox>
						<DrivenBox>
							<DrivenTypography component='p' variant='h3' sx={{
								fontSize: { xs: 18 },
								mb: 1
							}}>
								Store Hours
							</DrivenTypography>
							<DrivenTypography
								component='div'
								variant='body2'
								sx={{
									display:'flex',
									flexDirection: 'column',
									fontWeight: 400,
									color: theme.palette.grey[800],
								}}
							>
								{formatStoreHours(consolidateStoreHours(store.hours), theme)}
							</DrivenTypography>
						</DrivenBox>
					</DrivenBox>
					<DrivenBox
						mx={{
							xs: 0,
						}}
						position={{ md: 'relative' }}
						width={{ md: '100%' }}
					>
						<DrivenBox
							width='100%'
							height={{ xs: '33vh', sm: '40vh', md: 'auto' }}
							position={{ md: 'absolute' }}
							top={{ md: '0' }}
							right={{ md: '0' }}
							bottom={{ md: '0' }}
							left={{ md: '0' }}
						>
							<MapWrapper
								apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
								libraries={['places']}
								setStatus={setMapStatus}
							>
								<DrivenMap
									status={mapStatus}
									center={{
										lat: store.store_lat,
										lng: store.store_long,
									}}
									draggable={false}
								>
									<Marker
										active
										key={store.store_id}
										position={{
											lat: store.store_lat,
											lng: store.store_long,
										}}
										title={store.store_name}
										onClick={() => true}
										markerUrl={getMapMarker(
											store.current_brand,
											true
										)}
									/>
								</DrivenMap>
							</MapWrapper>
						</DrivenBox>
					</DrivenBox>
				</DrivenBox>
			</DrivenContainer>
			{locationDetailBlocks &&
				locationDetailBlocks
					.map((block) => {
						const {
							id,
							contentSectionButton,
							body: fallbackContent,
							heading: fallbackHeading,
							bodyBlocks,
						} = block as SectionType;

						const { rawMarkdownBody } =
							fallbackContent?.childMarkdownRemark;

						return (
							<DrivenBox
								key={id}
								sx={{
									backgroundColor: 'grey.100',
								}}
							>
								<DrivenContainer>
									<DrivenBox
										sx={{
											display: 'flex',
											flexDirection: isTablet ? 'row' : 'column',
											gap: isTablet ? 10 : 0,
											py: isTablet ? 5 : 3,
										}}
									>
										<DrivenBox width={isTablet ? '50%' : '100%'}>
											{!locationContentData?.heading && (
												<DrivenBox my={3}>
													<DrivenTypography
														textAlign={isTablet ? 'initial' : 'center'}
														variant={fallbackHeading?.style}
													>
														{fallbackHeading?.text}
													</DrivenTypography>
												</DrivenBox>
											)}
											<DrivenBox className='locationContent'>
												<MarkdownContent>
													{locationContent(rawMarkdownBody)}
												</MarkdownContent>
											</DrivenBox>
											{/* Call now button */}
											{!isTablet && ComponentMatch(
												contentSectionButton,
												'ContentfulButton',
												{
													url: `tel:${formatPhoneNumber(
														store.organic_phone || store.store_phone
													)}`,
												}
											)}
										</DrivenBox>
										<DrivenBox width={isTablet ? '50%' : '100%'}>
											<DrivenBox my={3}>
												{/* This is only pulling in the check marks below location services...  */}
												{locationOffers(bodyBlocks[1])}
											</DrivenBox>
											{/* Chip list */}
											{locationContentData?.serviceArea
												?.childMarkdownRemark?.rawMarkdownBody && (
												<DrivenBox my={3}>
													{ComponentMatch(
														[bodyBlocks[0]],
														'ContentfulChipList',
														{
															chips:
																locationContentData?.serviceArea?.childMarkdownRemark?.rawMarkdownBody
																	.split('|')
																	.filter((each) => each),
														}
													)}
												</DrivenBox>
											)}
										</DrivenBox>
									</DrivenBox>
								</DrivenContainer>
							</DrivenBox>
						);
					})}
			{store.testimonials && <CustomerTestimonials locationId={store.testimonials} />}
			{ctaBanner && <CTABanner {...ctaBanner} />}
		</DrivenBox>
	);
};

export const Head = ({ data: { storeLocation, contentfulLocationContent, detailPage }}: any) => {
	const {
		seoTitle,
		seoDescription,
		canonicalUrl,
		ogImageUrl,
		structuredData,
	} = generateHeadData(storeLocation, contentfulLocationContent, detailPage);

	return (
		<>
			<SEO
				path={`/${storeLocation.path}/`}
				title={seoTitle}
				description={seoDescription}
				canonical={canonicalUrl}
				ogImageUrl={ogImageUrl}
			/>
			<script type='application/ld+json'>
				{JSON.stringify(structuredData)}
			</script>
		</>
	);
};

export const data = graphql`
	query DetailsPageQuery($id: String, $node_locale: String, $store_id: Int) {
		ctaBanner: contentfulCtaBanner(
			contentful_id: { eq: "77ygezZ3jthDun8FZxcVJM" }
			node_locale: { eq: $node_locale }
		) {
			...CTABannerFields
		}

		contentfulLocationContent(
			storeId: { eq: $store_id }
			node_locale: { eq: $node_locale }
		) {
			id
			storeId
			heading
			metaDescription
			titleTag
			content {
				childMarkdownRemark {
					rawMarkdownBody
				}
			}
			serviceArea {
				childMarkdownRemark {
					rawMarkdownBody
				}
			}
			mobileRepair
		}

		storeLocation(id: { eq: $id }) {
			id
			store_postcode
			store_phone
			organic_phone
			is_active
			path
			store_address
			store_city
			store_id
			store_lat
			store_long
			store_name
			store_state
			sub_brand
			brand
			current_brand
			historic_brand
			geo_address
			testimonials

			hours {
				Monday {
					close
					isOpen
					open
				}
				Tuesday {
					close
					isOpen
					open
				}
				Wednesday {
					close
					isOpen
					open
				}
				Thursday {
					close
					isOpen
					open
				}
				Friday {
					close
					isOpen
					open
				}
				Saturday {
					close
					isOpen
					open
				}
				Sunday {
					close
					isOpen
					open
				}
			}
		}

		detailPage: allContentfulPage(
			filter: {
				node_locale: { eq: $node_locale }
				contentful_id: { eq: "2c8E17mCFtx2c7gtbfLgt2" }
			}
		) {
			nodes {
				blocks {
					...HeroFields

					# ...SectionFields

					... on ContentfulSection {
						__typename
						id
						heading {
							text
							level
							style
						}
						# // FIXME: Something going on with actions on sections. Conflicting names.  JB:12/23
						contentSectionButton: actions {
							...ButtonFields
						}
						body {
							childMarkdownRemark {
								rawMarkdownBody
							}
						}
						bodyBlocks {
							...ListFields
						}
						backgroundColor
						inline
						blocks {
							...GridFields

							...TabsFields

							...ContentSectionFields

							...CardGroupFields

							...AccordionFields

							...ImageBannerFields
						}
						disclaimer {
							childMarkdownRemark {
								rawMarkdownBody
							}
						}
					}
				}
			}
		}
	}
`;

export default LocationDetailsPage;
