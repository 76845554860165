import { Fragment } from 'react';
import { DrivenBox, DrivenContainer, DrivenLink, DrivenTypography } from '@drivenbrands/driven-components';
import { useTheme } from '@mui/material';

interface PageContext {
	pagePath: string;
}

interface BreadcrumbsProps {
	pageContext: PageContext;
}

export const Breadcrumbs = ({ pageContext }: BreadcrumbsProps) => {
	const { pagePath } = pageContext;
	const theme = useTheme();

	if (pagePath === '/' || pagePath === '/blog' || pagePath === '/exclusiveoffers') {
		return null;
	}

	const pathSegments = pagePath.split('/').filter(Boolean);

	return (
		<DrivenBox>
			<DrivenContainer maxWidth='lg'>
				<DrivenBox display='flex' alignItems='center' flexWrap='wrap'>
					<DrivenLink href='/' sx={{
						fontWeight: 700,
						color: theme.palette.grey[500],
						textDecoration: 'underline',
					}}>
						Auto Glass Now
					</DrivenLink>
					<DrivenTypography sx={{ px: 1, color: '#9E9E9E' }}> / </DrivenTypography>
					{pathSegments.map((segment, index) => {
						const isLast = index === pathSegments.length - 1;
						const href = `/${pathSegments.slice(0, index + 1).join('/')}`;
						const displayText = segment.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase());

						return (
							<Fragment key={href}>
								{isLast ? (
									<DrivenTypography sx={{ color: theme.palette.common.black, fontWeight: 600 }}>
										{displayText}
									</DrivenTypography>
								) : (
									<DrivenLink href={href} sx={{
										fontWeight: 700,
										color: theme.palette.grey[500],
										textDecoration: 'underline',
									}}>
										{displayText}
									</DrivenLink>
								)}
								{!isLast && <DrivenTypography sx={{ px: 1 }}> / </DrivenTypography>}
							</Fragment>
						);
					})}
				</DrivenBox>
			</DrivenContainer>
		</DrivenBox>
	);
};
