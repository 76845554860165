/* TODO: Determine if this util is being used. 
If not should remove to avoid confusion. 
Navigation is being pulled in from Contentful.
This file appears to be a mockup of what comes from contentful.  */

import { NavigationItem } from '@drivenbrands/driven-components';
import { captureCustomDataLayerEvent } from '../services';

export const getNavigationItems = (storeId?: string): NavigationItem[] => {
	// If storeId is included as part of context, likely on the location detail page, then include as additional parameter
	const optionalParameter = storeId ? { store_id: storeId } : {};

	return [
		{
			text: 'Auto Glass',
			href: '',
			submenu: [
				{ text: 'Windshield Repair', href: '/windshield-repair/' },
				{
					text: 'Windshield Replacement',
					href: '/windshield-replace/',
				},
				{
					text: 'Car Window & Rear Window Windshield Replacement',
					href: '/car-window-and-rear-windshield-replace/',
				},
				{
					text: 'Mobile Auto Glass Repair',
					href: '/mobile-auto-glass-repair/',
				},
			],
		},
		{ text: 'Calibration', href: '/calibration/' },
		{
			text: 'Payment Options',
			href: '',
			submenu: [
				{
					text: 'Buy Now, Pay Later!',
					href: '/buy-now-pay-later/',
				},
				{ text: 'Payment Options', href: '/payment-options/' },
				{
					text: 'Insurance Claims Management',
					href: '/insurance-claims-management/',
				},
				{ text: 'Promotions', href: '/promotions/' },
			],
		},
		{
			text: 'Why AGN?',
			href: '',
			submenu: [
				{ text: 'About Us', href: '/about/' },
				{ text: 'No Hassle Warranty', href: '/warranty/' },
				{ text: 'Contact Us', href: '/contact-us/' },
			],
		},
		{
			text: 'Find a Location',
			href: '/our-locations/',
			callback: () => {
				captureCustomDataLayerEvent('find_a_location_click', {
					request_quote_cta_location: 'top_nav',
					...optionalParameter,
				});
			},
		},
		{
			buttonTypography: 'smallButton1',
			text: 'Request a Quote',
			href: '/request-quote/',
			type: 'button',
			callback: () => {
				captureCustomDataLayerEvent('request_quote_click', {
					request_quote_cta_location: 'top_nav',
					...optionalParameter,
				});
			},
		},
	];
};

export const agnUtilNavList: NavigationItem[] = [
	{
		text: 'Careers',
		href: '/careers/',
	},
	{
		text: 'Contact Us',
		href: '/contact-us/',
	},
];

export const footerCategories = [
	{
		text: 'Auto Glass & Calibration',
		href: '/auto-glass-services',
		submenu: [
			{
				href: '/auto-glass-services/',
				text: 'Auto Glass & Calibration',
			},
			{
				href: '/windshield-repair/',
				text: 'Windshield Repair',
			},
			{
				href: '/windshield-replace/',
				text: 'Windshield Replacement',
			},
			{
				href: '/car-window-and-rear-windshield-replace/',
				text: 'Car Window & Rear Window Windshield Replacement',
			},
			{
				href: '/insurance-claims-management/',
				text: 'Insurance Claims Management',
			},
		],
	},
	{
		text: 'Company',
		href: '',
		submenu: [
			{
				href: '/about/',
				text: 'About Us',
			},
			{
				href: '/buy-now-pay-later/',
				text: 'Buy Now Pay Later',
			},
			{
				href: '/fleet-and-commercial/',
				text: 'Fleet & Commercial',
			},
			{
				href: '/promotions/',
				text: 'Promotions',
			},
		],
	},
	{
		text: 'Careers',
		href: '',
		submenu: [
			{
				href: '/careers/',
				text: 'Careers',
			},
		],
	},
	{
		text: 'Help',
		href: '',
		submenu: [
			{
				href: '/our-locations/',
				text: 'Find a Location',
			},
			{
				href: '/warranty/',
				text: 'No Hassle Warranty',
			},
			{
				href: '/covid-19/',
				text: 'Covid-19 And Your Safety',
			},
			{
				href: '/contact-us/',
				text: 'Contact Us',
			},
		],
	},
];

export const footerLegal = {
	text: '©2022 Auto Glass Now, All Rights Reserved.',
	href: '',
	submenu: [
		{
			href: '/web-accessibility/',
			text: 'Web Accessibility',
		},
		{
			href: '/california-privacy-policy/',
			text: 'California Privacy Policy',
		},
		{
			href: '/privacy-policy/',
			text: 'Privacy Policy',
		},
		{
			href: '/terms',
			text: 'Terms of Use',
		},
	],
};

export const footerSocial = {
	text: 'Connect with us',
	href: '',
	submenu: [
		{
			href: '',
			text: '',
			submenu: [
				{
					href: 'https://www.facebook.com/Take5OilChange/',
					external: true,
					text: 'facebook-square',
				},
				{
					href: 'https://twitter.com/take5_oilchange',
					external: true,
					text: 'twitter',
				},
				{
					href: 'https://www.instagram.com/take5oilchange/',
					external: true,
					text: 'instagram',
				},
			],
		},
	],
};
