// Ideally this would be returned from our endpoint, but creating a lookup in the meantime
export enum DayOfWeekToSpanish {
	Sunday = 'Domingo',
	Monday = 'Lunes',
	Tuesday = 'Martes',
	Wednesday = 'Miércoles',
	Thursday = 'Jueves',
	Friday = 'Viernes',
	Saturday = 'Sábado',
}

// TODO: handle mixed case. 
// TODO: Return english if not in list to avoid duplicate text. 
// example   >  AssortedWordsToSpanish['Call For Quote'] : 'Call For Quote';
// should be >  AssortedWordsToSpanish['English Only text']
// TODO: Better localization framework. 
export enum AssortedWordsToSpanish {
	Address = 'Dirección',
	Closed = 'Cerrado',
	Directions = 'Direcciones',
	'Enter Email Address' = 'Ingresa correo electrónico',
	Found = 'encontrado',
	'Get Directions' = 'Obtener las direcciones',
	'Open Until' = 'Abrierto Hasta',
	'Opens Tomorrow At' = 'Abre mañana a las',
	'Phone Number' = 'número de teléfono',
	'Request a quote' = 'Solicita un Presupuesto',
	Result = 'Resultado',
	'Sign Up' = 'Suscríbete',
	'Sign Up For Our Mailing List!' = '¡Suscríbete para recibir información!',
	'Store Hours' = 'Horario de la tienda',
	'View By State' = 'Encontrar por Estado',
	'Find a location' = 'Encuentra una ubicación',
	'Call For Quote' = 'Solicite una cotización',
	'Get a Quote' = 'Consigue una cotización'
}
