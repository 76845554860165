import { DrivenBox, DrivenContainer } from '@drivenbrands/driven-components';
import { Paper, PaperProps, useMediaQuery } from '@mui/material';
import { Breakpoint, styled, useTheme } from '@mui/material/styles';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { CSSProperties } from 'react';

/* Notes on this component.  The origen of this hero is the design system, however AGN has requested significant customization to the hero image
that deviates enough from the design system hero that a custom hero for AGN is warranted.
Key differences to note for use.
AGNCustomHero accepts the entire media object instead of JSX to enable use of gatsbyImage
It accepts a prop called useCustomHero from hero that is currently set to toggle true on the home page only "/".
Most key differences are the styling of customHomePageHero.
*/

type Layout = 'fixed' | 'fullWidth' | 'constrained';

export interface Image {
	description: string;
	title: string;
	publicUrl: string;
	gatsbyImageData: {
		backgroundColor: string;
		height: number;
		images: {
			fallback: {
				src: string;
				srcSet: string;
				sizes: string;
			};
			sources: Array<{
				srcSet: string;
				sizes: string;
				type: string;
			}>;
		};
		layout: Layout;
		width: number;
	};
}

export type DrivenHeroProps = {
	useCustomHero?: boolean;
	coverImage?: string;
	customMaxWidth?: Breakpoint;
	darkMode?: boolean;
	backgroundColor?: string;
	overlay?: string;
	media?: Image | React.ReactElement; // Add a union type for the media prop to allow JSX elements until GatsbyImage is integrated everywhere Hero is used.
	mediaProps?: CSSProperties & {
		anchorHalfWidth?: boolean;
	};
	minHeroHeight?: 'none' | '200px' | '300px' | '400px';
};

const CustomHomepageHero = styled(DrivenBox, {
	shouldForwardProp: (props) => props !== 'mediaProps',
})<{ mediaProps?: CSSProperties & { anchorHalfWidth?: boolean } }>(
	({ mediaProps, theme }) => {
		const { anchorHalfWidth, ...restMediaProps } = mediaProps || {};
		return {
			width: '100%',
			display: 'flex',
			flex: 0,
			zIndex: 0,
			[theme.breakpoints.up(1920)]: {
				height: '500px',
			},
			'> div': {
				width: '100%',
				height: 'auto',
				...restMediaProps,
			},
			[theme.breakpoints.down('md')]: {
				// gatsby-image-wrapper and above-image work to gether to allow the colored div above the image
				// on mobile for the customHero.
				'.gatsby-image-wrapper': {
					width: '170%',
					height: '100%',
					objectFit: 'cover',
					objectPosition: 'right',
					position: 'absolute',
					top: '350px',
					zIndex: 1,
				},

				position: 'relative',
				'&::before': {
					content: '""',
					display: 'block',
					backgroundColor: '#004E82',
					width: '115%',
					height: '390px',
					position: 'absolute',
					zIndex: 2,
				},
			},
			[theme.breakpoints.down('sm')]: {
				'.gatsby-image-wrapper': {
					width: '200%',
					height: '300px',
				},
			},

			'.gatsby-image-wrapper': {
				marginRight: 0,
			},
			'.above-image': {
				display: 'none',
				[theme.breakpoints.down('md')]: {
					display: 'block',
					backgroundColor: '#004E82',
					width: '100%',
					height: '390px',
					position: 'relative',
					zIndex: 1,
				},
			},
		};
	}
);

const DefaultHero = styled(DrivenBox, {
	shouldForwardProp: (props) => props !== 'mediaProps',
})(({ mediaProps, theme }) => ({
	width: '100%',
	display: 'flex',
	flex: 0,
	zIndex: 0,
	paddingLeft: 0,

	'> img': {
		...mediaProps,

		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.up(1535)]: {
			left: '55%',
		},
	},
}));

export function AgnCustomHero({
	useCustomHero = false,
	coverImage,
	customMaxWidth = 'lg',
	darkMode = true,
	backgroundColor,
	overlay,
	media,
	mediaProps,
	minHeroHeight = '400px',
	...restProps
}: DrivenHeroProps & Pick<PaperProps, 'children'>) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

	const homepageMediaProps: CSSProperties = {
		...mediaProps,
		position: isMobile ? 'relative' : 'absolute',
		left: 'unset',
		right: 0,
		transform: 'unset',
	};
	const StyledPaper = styled(Paper, {
		shouldForwardProp: (props) =>
			props !== 'darkMode' &&
			props !== 'coverImage' &&
			props !== 'minHeroHeight' &&
			props !== 'overlay',
	})<Omit<DrivenHeroProps, 'media'>>(
		({ darkMode = true, theme, coverImage, minHeroHeight, overlay = '' }) => ({
			background: coverImage
				? `${overlay ? `${overlay},` : ''
				} url(${coverImage}) center center / cover no-repeat`
				: '',
			backgroundColor: backgroundColor || theme.palette.common[darkMode ? 'black' : 'white'],
			color: theme.palette.common[darkMode ? 'white' : 'black'],
			display: 'flex',
			position: 'relative',
			minHeight: minHeroHeight,

			[theme.breakpoints.down('md')]: {
				flexDirection: 'column-reverse',
				marginBottom: useCustomHero
					? '300px'
					: 0 /* on mobile, the custom hero colored div above the image must be position absolute, it will run into
        with the rest of the page without a margin to push the page contents down */,
			},
		})
	);

	const StyledContentBox = styled(DrivenBox, {
		shouldForwardProp: (props) => props !== 'darkMode' && props !== 'overlay',
	})<Omit<DrivenHeroProps, 'media'>>(({ theme }) => ({
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		padding: theme.spacing(0, 8),
		zIndex: 1,

		[theme.breakpoints.down('md')]: {
			padding: useCustomHero ? theme.spacing(0, 3) : theme.spacing(6, 3, 3),
		},
	}));

	const calculatedMediaProps: CSSProperties & { anchorHalfWidth?: boolean } = {
		...mediaProps,
		...(mediaProps?.anchorHalfWidth && {
			position: isMobile ? 'relative' : 'absolute',
			left: isDesktop ? 'unset' : '100%',
			right: isDesktop ? 0 : 'unset',
			transform: `translateX(${isDesktop ? 0 : isMobile ? '-100%' : '-48vw'})`,
		}),
	};

	function isImage(
		media: Image | React.ReactElement | undefined
	): media is Image {
		return (media as Image).description !== undefined;
	}

	const renderMedia = (
		media?: Image | React.ReactElement,
		mediaProps?: any,
		customHero?: boolean
	) => {
		if (!media) {
			return null;
		}

		if (React.isValidElement(media)) {
			return <DefaultHero mediaProps={mediaProps}>{media}</DefaultHero>;
		}

		if (isImage(media) && !customHero && media.publicUrl) {
			return (
				<DefaultHero mediaProps={mediaProps}>
					<img src={media.publicUrl} alt={media.description} />
				</DefaultHero>
			);
		} if (isImage(media) && media.gatsbyImageData) {
			return (
				<CustomHomepageHero mediaProps={homepageMediaProps}>
					<div className='overlay' />
					<div className='above-image' />
					<GatsbyImage
						image={media.gatsbyImageData}
						alt={media.description}
						loading='eager'
					/>
				</CustomHomepageHero>
			);
		}
		return null;

	};

	return (
		<StyledPaper
			coverImage={coverImage}
			darkMode={darkMode}
			elevation={0}
			minHeroHeight={minHeroHeight}
			overlay={overlay}
			square
		>
			<StyledContentBox>
				<div className='text-content'>
					{(!useCustomHero || !isMobile) && (
						/* To enable the headline, subhead and button to correctly render for the customHero
						We render DrivenContainer in a different place on desktop and mobile */
						<DrivenContainer
							maxWidth={customMaxWidth}
							sx={{
								[theme.breakpoints.down(1151)]: {
									padding: useCustomHero ? '30px 40px' : 0,
									// paddingBottom: useCustomHero ? '20px' : 0,
								},
							}}
						>
							{restProps.children}
						</DrivenContainer>
					)}
				</div>
			</StyledContentBox>
			{renderMedia(media, calculatedMediaProps, useCustomHero)}
			{useCustomHero && isMobile && (
				/* This is the mobile render location for the headline, subhead and button. */
				<DrivenContainer
					maxWidth={customMaxWidth}
					sx={{
						position: 'absolute',
						top: '20px',
						left: '20px',
						width: `calc(100% - 30px)`,
						marginTop: '0 !important',
					}}
				>
					{restProps.children}
				</DrivenContainer>
			)}
		</StyledPaper>
	);
}
