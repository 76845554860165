import { DrivenBox, DrivenLink, DrivenTypography } from '@drivenbrands/driven-components';
import { Theme } from '@mui/material';

type StoreLocationBreadcrumbProps = {
	path: string;
	store_city: string;
	store_state: string;
	storeStateAbbr: string;
	theme: Theme;
};

export const LocationDetailBreadcrumb = ({ path, store_city, store_state, storeStateAbbr, theme }: StoreLocationBreadcrumbProps) => {
	const locationCode = path.match(/\d+/)?.[0] ?? '';

	return (
		<DrivenBox display='flex' alignItems='center' flexWrap='wrap' sx={{ py: { xs: 2, md: 3 }, color: theme.palette.grey[700] }}>
			<DrivenLink href={`/our-locations/${storeStateAbbr}`} sx={{ textDecoration: 'underline' }}>
				{store_state}
			</DrivenLink>
			<DrivenTypography sx={{ px: 1, color: '#9E9E9E' }}> / </DrivenTypography>
			<DrivenLink href={`/our-locations/${storeStateAbbr}/${store_city.toLowerCase().replace(/\s+/g, '-')}`} sx={{ textDecoration: 'underline' }}>
				{store_city}
			</DrivenLink>
			<DrivenTypography sx={{ px: 1 }}> / </DrivenTypography>
			<DrivenTypography sx={{ color: theme.palette.common.black, fontWeight: 600 }}>
				{locationCode}
			</DrivenTypography>
		</DrivenBox>
	);
};
